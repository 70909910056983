import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import queryString from 'qs'

import { Card, Heading, Flex, Paragraph } from 'reakit'

import { Container, Body, Form, TopBar, HomeFooter } from '../components/layouts'

import {
	Link,
} from '../components/ui'

import { reset, clearAuth } from '../factory'

import Layout from '../components/layout'

import theme from '../theme/content'

class Reset extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			formValidate: null,
		}

		this.setValues = this.setValues.bind(this)
		this.submitForm = this.submitForm.bind(this)
		this.setTokenAndEmail = this.setTokenAndEmail.bind(this)
	}

	componentDidMount() {
		this.props.clear()
	}

	setTokenAndEmail(token, email) {
		this.setState({
			token,
			email
		})
	}

	setValues(e) {
		if (e.target) {
			this.setState({
				[e.target.name]: e.target.value,
				formValidate: event.target.value.length >= 6 ? null : 'The password must have at least 6 characters.',
			})
		}
	}

	submitForm(e) {
		e.preventDefault()

		const query = queryString.parse(location.search.slice(1))
		const email = query.email.replace(/\++$/, "").trim()
		const token = query.code.replace(/ /g, '+').trim()

		if (this.state.password && !this.state.formValidate) {
			if (this.state.password === this.state.password_confirm) {
				if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
					this.setState({
						formValidate: null,
					})

					this.props.resetPassword(email, token, this.state.password)
				} else {
					this.setState({
						formValidate: `Your email doesn't look correct. Currently, it's ${email}. If you see a problem please resend yourself a new link with the correct email address.`,
					})
				}
			} else {
				this.setState({
					formValidate: "Your passwords do not match.",
				})
			}
		} else {
			this.setState({
				formValidate: "Please fill the form.",
			})
		}
	}

	render() {
		const content = theme.pagesContent.reset

		return (
			<Layout>
				<TopBar />
				<Container maxHeight="100vh">
					<Body bgColor={'transparent'} bg={content.bg} maxWidth="100%">
						<Flex
							width={'100%'}
							height={'100vh'}
							alignItems={'center'}
							justifyContent={'center'}
							flexDirection={'column'}
						>
							<Card
								background={'transparent'}
								boxShadow="none"
								width="35%"
								minWidth="350px"
							>
								<Heading
									as="h1"
									fontSize="35px"
									margin="0 0 0.5em 0"
									lineHeight="1.1em"
								>
									{content.title}
								</Heading>
								<Flex margin="0 0 1rem 0" column>
									<Form
										fields={content.resetForm.fields}
										setValues={this.setValues}
										submitForm={this.submitForm}
										submitBtnText={this.props.peding ? 'Resetting password...' : content.resetForm.submitField.text}
										error={this.props.error || this.state.formValidate}
									/>
									<Paragraph>{this.props.success && 'Your password has been reset'}</Paragraph>
								</Flex>
								<Flex margin="0" row>
									<Heading as="h6" margin="-0.5em 1em 0 0">
										<Link as="a" href={content.login.link}>
											{content.login.text}
										</Link>
									</Heading>
								</Flex>
							</Card>
						</Flex>
					</Body>
				</Container>
				<HomeFooter />
			</Layout>
		)
	}
}

Reset.propTypes = {
	resetPassword: PropTypes.func,
	peding: PropTypes.bool,
	success: PropTypes.bool,
	error: PropTypes.string,
	clear: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		peding: state.authState.pending,
		success: state.authState.success,
	 	error: state.authState.error,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		clear: () => dispatch(clearAuth()),
		resetPassword: (email, token, password) => dispatch(reset(email, token, password)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Reset)
